import React from 'react';
import {Image, Segment,} from 'semantic-ui-react';
import profileImg1 from "../../assets/stadler.jpg";
import ddg from "../../assets/ddg3.png";
import componentStyles from "../../templates/default.module.css";

const GreetingsDDG = () => (
    <Segment>
        <Image src={ddg} floated={'right'} size={'medium'}  className={componentStyles.greetingsImg}/>
        <Image src={profileImg1} floated={'left'} size={'medium'}  className={componentStyles.greetingsImg}/>
        <p>Sehr geehrte Damen,</p>
        <p>sehr geehrte Herren,</p>
        <p>Kinder und Jugendliche stellen die Zukunft einer jeden Gesellschaft dar. Ihnen ein unkompliziertes Erwachsenwerden mit best-möglicher Ausbildung und Gesundheits-vorsorge zu garantieren sollte jedwede Anstrengung in einem Staat rechtfertigen.</p>
        <p>Die Hautkrankheiten nehmen dabei unter den chronisch-entzündlichen Erkrankungen den größten Anteil ein. Mehr als 15 % der Kinder leiden an allergischem Schnupfen, 15 – 20 % an einer Neurodermitis. Das Melanom ist zwar ein seltener Tumor im Kindesalter, aber eine der häufigsten Todesursachen unter den soliden Tumoren im Kindesalter.
            Die Deutsche Dermatologische Gesellschaft hat gerade in den letzten Jahren für eine Stärkung der pädiatrischen Dermatologie in den Universitätskliniken und akademischen Lehrkrankenhäusern geworben. Führende Vertreter unseres Faches sind in ihrer klinischen und wissenschaftlichen Tätigkeit bestrebt, die Erkenntnisse über Haut-krankheiten im Kindesalter zu mehren und neue therapeutische Wege zu finden, um den kleinen Patienten und Jugendlichen die notwendige medizinische Versorgung zu garantieren.</p>
        <p>In der Vergangenheit verfügte fast jede Hautklinik über Betten für hautkranke Kinder. Bedauerlicherweise wurden diese im Rahmen der allgemeinen Ökonomisierung gestrichen. Diesen Mangelzustand zu beseitigen, die Forschung an Hautkrankheiten im Kindesalter zu fördern und die Therapien weiter-zuentwickeln, fühlen wir uns als wissen-schaftliche Fachgesellschaft verpflichtet.</p>
        <p>Die Arbeitsgemeinschaft Pädiatrische Derma-tologie hat gerade in den letzten Jahren hierzu wertvolle Beiträge geleistet.</p>
        <p>Wir begrüßen es daher sehr, dass sich die Deutsche Stiftung Kinderdermatologie für die Schwächsten in unserer Gesellschaft engagiert, das notwendige Herz zeigt und unverzichtbar die nötigen Sach- und Finanzmittel bereitstellt, für unsere zukünftige Generation die bestmögliche Versorgung von Hautkrankheiten im Kindesalter sicher zu stellen.</p>
        <p>Die Deutsche Dermatologische Gesellschaft wünscht der Deutschen Stiftung Kinder-dermatologie überaus großen Erfolg in dieser so wichtigen Aufgabenstellung.</p>
        <p>Mit freundlichen Grüßen</p>
        <p>Prof. Dr. med. Rudolf Stadler</p>
    </Segment>

);

export default GreetingsDDG;